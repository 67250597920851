body,
html{
    height: 100%;
    font-family: Arial,Helvetica,sans-serif;

    #compatabilityModeError{
        #compatabilityMessage{
            h1{
                color: #0460A9;
            }
        }
        img{
            vertical-align: middle;
        }
    }
}